.contact-parents {
  align-items: center;
  padding: 27px 0px 35px;
}
.contact-parents-btn {
  text-align: center;
  font-size: 20px;
  font-family: 'Nanum_Restart';
  border: 1px #A96B6C;
  padding: 6px 50px 6px 50px;
  border-radius: 10px;
  border-style: solid;
}

.contact-buttons-wrapper {
  text-align: center;
  background-color: #fff;
  width: 90%;
  max-width: 400px;
  border-radius: 8px;
}
.contact-parents-title {
  padding-top: 25px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #EFE9E9;
  height: 75px;
  font-size: 20px;
  font-family: 'Nanum_Restart';
  font-weight: 550;
  border-radius: 8px;
}
.contact-space {
  padding-top: 25px;
}
.contact-section {
  flex-direction: column;
  align-items: center;
}
.contact-icons {
  display: flex;
  width: 100%;
}
.contact-button {
  width: 22px;
  height: 22px;
  margin-left: 10px;
}
.contact-section-title {
  position: relative;
  display: flex;
  font-size: 20px;
  font-weight: 400;
  justify-items: center;
  align-items: center;
  text-align: center;
  color: #000;
  gap: 44px;
  margin: 0 auto;
  font-family: 'Nanum_Restart';
}
.phone-numbers {
  display: flex;
}
.phone-numbers img {
  width: 22px;
  height: 22px;
  margin-left: 10px;
}
.seperator-line {
  margin-left: 20px;
  margin-right: 20px;
  width: auto;
  height: 1px;
  background-color: #000;
}