.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
  }
  
.v--modal {
    background-color: white;
    text-align: left;
    border-radius: 3px;
    box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
    padding: 0;
}
.v--modal-overlay .v--modal-box {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}
.c-guestbook-header {
    padding: 20px 20px;
    font-weight: 500;
    font-size: 1.1rem;
    font-family: 'Nanum_Restart';
    color: #000;
}
.btn-close {
    float: right;
    cursor: pointer;
    margin: 5px 0 0 0px;
    width: 5px;
    height: 5px;
}
.c-guestbook-modal-wrap {
    display: flex;
    text-align: left;
    background: #fff;
    font-size: 18px;
    font-family: 'Nanum_Restart';
    color: #544f4d;
}
.c-guestbook-modal-inner {
    display: flex;
    flex: 1;
    flex-direction: column;
}
.c-guestbook-modal-inner table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}
.c-guestbook-modal-inner tr {
    border-top: 1px solid #eee;
}
.c-guestbook-modal-inner th {
    padding: 0 0 0 20px;
    height: 60px;
    vertical-align: middle;
    font-size: .9rem;
    font-weight: 500;
    color: #989898;
}
.c-guestbook-modal-inner td {
    padding: 0;
}
.c-guestbook-modal-inner td input, .c-guestbook-modal-inner td textarea {
    border: none;
    padding: 0 10px;
    width: calc(100% - 20px);
    height: 40px;
    color: #000;
    font-size: 18px;
    font-family: 'Nanum_Restart';
    letter-spacing: var(--font-gothic-spacing);
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    border-radius: none;
    -webkit-border-radius: 0;
    box-sizing: border-box;
    vertical-align: top;
}
.c-guestbook-modal-inner td textarea {
    width: calc(100% - 40px);
    height: 96px;
    margin: 20px;
    padding: 12px 16px;
    resize: vertical;
    background: #fcfcfc;
    line-height: 24px;
    border-radius: 5px;
    border: 1px solid #eee;
}
.btn-submit {
    display: block;
    margin: 0;
    height: auto;
    line-height: 60px;
    background-color: #CCBABA;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Nanum_Restart';
    cursor: pointer;
    text-align: center;
    border: 1px;
    border-radius: 3px;
}