.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
  }
  
  .modal-image {
    max-width: 80%;
    max-height: 80%;
    transition: transform 0.3s ease-in-out;
  }
  
  .overlay.left .modal-image {
    transform: translateX(-100%);
  }
  
  .overlay.right .modal-image {
    transform: translateX(100%);
  }
  
  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    color: white;
    cursor: pointer;
  }
  
  .overlay-arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  .overlay-arrows_right {
    display: flex;
    background-color: #6d6d6d;
    justify-content: space-between;
    position: absolute;
    right: 0;
    width: 30px;
    height: 30px;
    top: 50%;
    z-index: 999;
  }
  
  .overlay-arrows_left {
    display: flex;
    background-color: #6d6d6d;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    width: 30px;
    height: 30px;
    left: 0;
    z-index: 999;
  }
  