.floating-button {
    position: fixed;
    bottom: 12%;
    right: 5%;
    width: 50px;
    height: 50px;
    /* border-radius: 50%; */
    background-color: transparent;
    /* color: white; */
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); */
    cursor: pointer;
    /* z-index: 1000; */
    transition: background-color 0.3s;
}

.floating-image {
    width: 45px;
    height: 45px;
    /* border-radius: 50%; */
    align-items: center;
    justify-content: center;
}