.col-md {
  padding-left: 0px;
  padding-right: 0px;
}
.row {
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px;
}
.container {
  padding: 0px;
  margin-left: 0px;
  margin-right: 0px;
}
/* 
  브라우저 창 width가 1024px보다 커지는 순간부터 적용
	데스크탑
*/
@media all and (min-width: 1024px) {
  .row {
    display: flex;
    padding-left: 500px;
    padding-right: 500px;
  }
}

/* 플로팅 버튼 */
#floating-map-button {
  position: fixed;
  display: flex;
  padding: 14px 19px;
  align-items: center;
  left: 50%;
  bottom: 125px;
  background-color: white;
  border-radius: 24px;
  transform: translateX(-50%);
  color: black;
  font-size: 16px;
  font-weight: 600;
  gap: 8px;
  cursor: pointer;
}

.main-section { 
  width: 100%;
  padding: 0 auto;
  margin: 0 auto;
}
.mainsection-text {
  padding: 40px 0px 30px 0px;
  gap: 12px;
  flex-direction: column;
  align-items: center;
}
.mainsection-text-1 {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  margin-bottom: 30px;
  color: #000;
  text-align: center;
  font-family: 'Nanum_Restart';
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.mainsection-text-2 {
  color: #000;
  text-align: center;
  font-family: 'Nanum_Restart';
  font-size: 20px;
  font-style: normal;
  font-weight: 550;
  line-height: 30px;
  letter-spacing: -0.18px;
}
.mainsection-image {
  width: 100%;
  height: auto;
  padding: 0px 25px 0px 25px;
}

.gallery-image-list-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.col-3 {
  padding: 5px;
}
.gallery-image {
  width: 100%;
  height: auto;
}

.invitation-section {
  display: flex;
  width: 100%;
  padding: 30px 0px 30px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}
.invitation-section-text1 {
  color: #A96B6C;
  text-align: center;
  margin-bottom: 15px;
  font-family: 'Nanum_Restart';
  font-size: 26px;
}
.invitation-section-text1-image {
  margin-bottom: 15px;
}
.invitation-section-text1-image img {
  width: 60px;
  height: 56px;
}

.invitation-section-text2 {
  margin-bottom: 16px;
  color: #000;
  text-align: center;
  font-family: 'Nanum_Restart';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 5%;
}
.invitation-section-image2 {
  padding-top: 20px;
  padding-bottom: 20px;
  object-fit: contain;
  background-color: #FDFAFA;
}
.invitation-section-text2-image {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
  height: auto;
}
.invitation-section-text3 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  font-family: 'Nanum_Restart';
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  gap: 3px;
}
.text3-inner {
  font-family: 'Nanum_Restart';
  font-size: 18px;
  margin: 0 5px;
  font-style: normal;
  font-weight: 400;
}
.contact-button {
  width: 22px;
  height: 22px;
}

/* 캘린더 */
.calender-section {
  background-color: #FDFAFA;
  padding-top: 20px;
  padding-bottom: 1px;
}
.calender-section-text {
  background-color: #FDFAFA;
  text-align: center;
  font-family: 'Nanum_Restart';
  font-style: normal;
  font-size: 20px;
  padding-bottom: 10px;
}
.calender-section-image {
  text-align: center;
  align-items: center;
  width: auto;
  height: 236px;
  object-fit: cover;
}
.calender-seperator-line {
  margin-left: 15px;
  margin-right: 15px;
  width: auto;
  height: 1px;
  /* background-color: #F4E7E8; */
  color: #A96B6C;
}
.countdown-timer {
  font-size: 20px;
  font-family: 'Nanum_Restart';
  background-color: #FDFAFA;
  text-align: center;
  margin: 20px 0;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

/* 갤러리 */
.gallery-section {
  display: flex;
  width: 100%;
  padding: 60px 0px 36px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.gallery-section-text {
  color: #A96B6C;
  text-align: center;
  font-family: 'Nanum_Restart';
  font-size: 26px;
  line-height: 21px;
}

.location-section {
  display: flex;
  padding: 60px 0px 20px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.location-section-text1 {
  color: #A96B6C;
  text-align: center;
  font-family: Nanum_Restart;
  font-size: 26px;
  line-height: 21px;
}
/* 네이버 지도 */
.location-map-section {
  z-index: 30;
  padding: 0px 20px 0px 20px;
}
.location-info-section {
  display: flex;
  padding: 24px 5px 40px 5px;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}
.location-info-section-text1 {
  align-items: center;
  text-align: center;
  color: #000;
  font-family: 'Nanum_Restart';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
  padding-bottom: 20px;
}
.location-info-section-text2 {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: #000;
  font-family: 'Nanum_Restart';
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}
/* 대중교통 */
.location-how-publictrans-section {
  display: flex;
  width: 100%;
  padding: 0px 20px 40px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}
.location-how-publictrans-section-text1 {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: #A96B6C;
  font-family: 'Nanum_Restart';
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  padding-bottom: 10px;
  padding-left: 20px;
}
.location-how-publictrans-section-list {
  padding-left: 30px;
  flex-direction: column;
  justify-content: center;
  font-family: 'Nanum_Restart';
  font-size: 19px;
  font-weight: 400;
  gap: 4px;
  align-self: stretch;
  line-height: 30px;
}

/* 버스, 자가용 */
.location-how2-section {
  display: flex;
  width: 100%;
  padding: 0px 20px 40px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}
.location-how2-section-text1 {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: #A96B6C;
  font-family: 'Nanum_Restart';
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  padding-bottom: 10px;
  padding-left: 20px
}
.location-how2-section-text2 {
  color: #000;
  font-family: 'Nanum_Restart';
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  padding-left: 30px;
}

/* 마음 전하실 곳 */
.congratulatory-section {
  padding: 60px 0px 36px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.congratulatory-section-title {
  color: #A96B6C;
  text-align: center;
  font-family: 'Nanum_Restart';
  font-size: 26px;
  line-height: 21px;
  margin: 0 auto;
  padding-bottom: 10px;
}
.congratulatory-section-buttons {
  padding: 30px 40px 20px 40px;
}
.congratulatory-section-btn {
  text-align: center;
  font-size: 20px;
  font-family: 'Nanum_Restart';
  border: 1px #A96B6C;
  padding: 6px 50px 6px 50px;
  border-radius: 10px;
  border-style: solid;
  max-width: 255px;
  margin: 0 auto;
}
.congratulatory-section-space {
  padding: 10px 10px;
}
.congratulatory-section-thanks-comment {
  background: #E677790A;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 35px;
  font-family: 'Nanum_Restart';
  padding: 25px 25px;
}

/* 방명록 */
.guest-book-section {
  padding: 20px 0px 20px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.guest-book-section-title {
  color: #A96B6C;
  text-align: center;
  font-family: 'Nanum_Restart';
  font-size: 26px;
  line-height: 21px;
}
.comment-form {
  display: flex;
  flex-direction: column;
  padding: 10px 15px 10px 15px;
  border: 1px;
  border-radius: 32px;
  float: right;
  margin-right: 20px;
  width: 120px;
  text-align: center;
  color: #ffffff;
  background-color: #CCBABA;
  font-family: 'Nanum_Restart';
  font-size: 18px;
}
.comment {
  position: relative;
  padding: 15px;
  margin-bottom: 10px;
  background-color: #fefefe;
  border-radius: 4px;
  box-shadow: 1px 1px 1px 1px #f0f0f0;
}
.comment strong {
  color: #544F4F;
}

.guestbook-wrap {
  margin-bottom: 40px;
}
.c-guestbook {
  margin: 0;
  font-size: .86rem;
}
.gothic {
  font-family: var(--font-gothic), BlinkMacSystemFont, Apple SD Gothic Neo, Helvetica Neue, Helvetica, Arial, Malgun Gothic, "\B9D1\C740 \ACE0\B515", Dotum, 돋움, sans-serif;
  letter-spacing: var(--font-gothic-spacing);
}
.c-guestbook .comments {
  padding: 0 var(--common-margin-lr);
  border: var(--border-size) solid var(--border-color);
  border-left: 0;
  border-right: 0;
}
.c-guestbook .item {
  position: relative;
  padding: .75rem 1rem;
  text-align: left;
  line-height: 1.5rem;
  background: hsla(0, 0%, 100%, .75);
  border-radius: 8px;
  margin-bottom: 6px;
}
.c-guestbook .item .close {
  cursor: pointer;
  line-height: 0;
  position: absolute;
  right: 1rem;
  text-align: right;
  margin-left: 10px;
}
.c-guestbook .item .date {
  color: #aaa;
  display: inline-block;
  font-size: 14px;
  font-family: 'Nanum_Restart';
    letter-spacing: 1px;
  margin-right: 4px;
  vertical-align: middle;
  white-space: nowrap;
  width: 100px;
}

.icon {
  border: none;
  background-color: #fff;
  width: 11px;
  height: 10px;
}
.delete-button {
  border: none;
  background-color: #fff;
  width: 11px;
  height: 10px;
}
.c-guestbook .item .name {
  color: #534F4F;
  font-family: 'Nanum_Restart';
}
.c-guestbook .item .text {
  color: #534F4F;
  font-family: 'Nanum_Restart';
}

.error-message {
  color: #A96B6C;
  margin-top: 10px;
  font-weight: bold;
}
/* 댓글 페이징 */
.pagination {
  background-color: #fff;
  display: flex;
  justify-content: left;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 18px;
  font-family: 'Nanum_Restart';
}
.pagination button {
  margin: 0 0px;
  padding: 5px 10px;
  float: left;
  border: none;
  color: #86767A;
  background-color: #fff;
  cursor: pointer;
}
.pagination button:hover {
  font-size: 18px;
  font-weight: bold;
  font-family: 'Nanum_Restart';
}
.pagination button:focus {
  outline: none;
  font-size: 18px;
  font-family: 'Nanum_Restart';
}

/* 소식공유 */
.share-news-section {
  padding: 60px 0px 0px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  
}
.share-news-section-title {
  color: #A96B6C;
  text-align: center;
  font-family: 'Nanum_Restart';
  font-size: 26px;
  line-height: 21px;
  padding-bottom: 30px;
}

/* 카카오 공유 */
.kakao-button {
  display: flex;
  padding-bottom: 10px;
  font-family: 'Nanum_Restart';
  justify-content: center;
  font-size: 20px;
}
.kakao-button button {
  display: flex;
  border: 0px #FFF;
  background-color: #FFF;
}
.kakao-button img {
  display: flex;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-top: 4px;
}

/* 링크 복사 */
.copy-link {
  padding-bottom: 50px;
  font-family: 'Nanum_Restart';
  text-align: center;
  font-size: 20px;
}
.copy-link img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

/* 좋아요 */
.like-button {
  display: flex;
  position: relative;
  background-color: #FFF;
  justify-content: center;
  margin: 0 auto;
  padding-bottom: 0px;
}
.like-button button {
  display: flex;
  border: 0px #FFF;
  background-color: #FFF;
  padding: 0;
}
.like-on {
  display: inline;
  color: #A96B6C;
  font-size: 38px;
  height: 46px;
}
.like-off {
  display: inline;
  color: #A96B6C;
  font-size: 38px;
  height: 46px;
}
.like-count {
  display: flex;
  font-size: 17px;
  font-family: 'Nanum_Restart';
  color: #A96B6C;
  justify-content: center;
  padding-bottom: 10px;
}

/* 감사인사 */
.thank-you-for-all {
  font-size: 14px;
  color: #A96B6C;
  text-align: center;
  gap: 5px;
  background-color: #E677790A;
  padding: 10px 10px;
  font-family: 'Nanum_Restart';
  line-height: 15px;
}

/* modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 27, 0.541);
  display: flex;
  align-items: center;
  z-index: 100;
}
/* modal - image */
.overlay img {
  display: block;
  max-width: 80%;
  max-height: 80%;
  z-index: 999;
}
.overlay > span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 32px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
}
.overlay-arrows_left {
  display: flex;
  background-color: #6d6d6d;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  left: 0;
  z-index: 999;
}

.overlay-arrows_left svg {
  width: 30px;
  height: 30px;
}

.overlay-arrows_right svg {
  width: 30px;
  height: 30px;
}

.overlay-arrows_right {
  display: flex;
  background-color: #6d6d6d;
  justify-content: space-between;
  position: absolute;
  right: 0;
  width: 30px;
  height: 30px;
  top: 50%;
  z-index: 999;
}

/* modal - account */
.account-info-title {
  display: flex;
  justify-content: center;
  padding: 15px 15px;
  width: 100%;
  border-radius: 8px;
  background-color: #EFE9E9B5;
  font-size: 22px;
  font-family: 'Nanum_Restart';
}
.account-popup {
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: #FFF;
  width: 85%;
}
.account-info-each {
  display: flex;
  width: 100%;
  padding: 12px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  font-family: 'Nanum_Restart';
}
.each-header {
  display: flex;
  padding: 4px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.each-body {
  display: flex;
  padding: 4px 0px;
  margin: 0 20px 0 20px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.each-title {
  color: #000;
  font-family: 'Nanum_Restart';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  text-transform: uppercase;
}
.each-line {
  width: 100%;
  height: 1px;
  margin: 0px 0px 0px 0px;
  justify-content: center;
  background: #E6E6E6;
}
.copy-success {
  width: 100%;
  background: #333;
  padding-bottom: 4px;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  font-family: 'Nanum_Restart';
  font-size: 17px;
  font-style: normal;
}
.each-copy-btn {
  display: flex;
  padding: 5px 9px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 10px;
  color: #000; 
  background: #ffffff;
  border-style: solid;
  border-color: #D4CBCB;
  border-width: 0.25px;
  font-family: 'Nanum_Restart';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
}
.account-popup-close {
  display: flex;
  width: 100%;
  padding: 12px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #F1F1F1;
  color: #000;
  text-align: center;
  font-family: 'Nanum_Restart';
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  text-transform: uppercase;
}
.each-account-text {
  color: #000;
  font-family: 'Nanum_Restart';
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-transform: uppercase;
}

/* font */
@font-face {
  font-family: 'NanumMyeongjo';
  src: local('NanumMyeongjo'), url(./fonts/Nanum_Myeongjo/NanumMyeongjo-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'NotoSansKR';
  src: local('NotoSansKR'), url(./fonts/Noto_Sans_KR/NotoSansKR-Regular.otf) format('truetype');
}
@font-face {
  font-family: 'Nanum_Restart';
  src: local('Nanum_Restart'), url(./fonts/Nanum_Restart/Nanum_Restart.ttf) format('truetype');
}